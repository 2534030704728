.night {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotateZ(40deg);
}

.star,
.single-star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, #fff, rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px #fff);

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: calc(50% - 1px);
    right: 0;
    height: 2px;
    background: linear-gradient(-45deg, rgba(0, 0, 255, 0), #fff, rgba(0, 0, 255, 0));
    border-radius: 100%;
    transform: translateX(50%) rotateZ(45deg);
    animation: shining 3s ease-in-out infinite;
  }

  &::after {
    transform: translateX(50%) rotateZ(-45deg);
  }
}

.star {
  animation: tail 3s ease-in-out infinite, falling 3s ease-in-out infinite;

  &:nth-child(1) {
    top: calc(50% - 100px);
    left: calc(50% - 250px);
    animation-delay: 1s;

    &::before,
    &::after {
      animation-delay: 1s;
    }
  }

  &:nth-child(2) {
    top: calc(50% - 50px);
    left: calc(50% - 200px);
    animation-delay: 1.2s;

    &::before,
    &::after {
      animation-delay: 1.2s;
    }
  }

  &:nth-child(3) {
    top: calc(50% - 0px);
    left: calc(50% - 150px);
    animation-delay: 1.4s;

    &::before,
    &::after {
      animation-delay: 1.4s;
    }
  }

  &:nth-child(4) {
    top: calc(50% - -50px);
    left: calc(50% - 200px);
    animation-delay: 1.6s;

    &::before,
    &::after {
      animation-delay: 1.6s;
    }
  }

  &:nth-child(5) {
    top: calc(50% - -100px);
    left: calc(50% - 250px);
    animation-delay: 1.8s;

    &::before,
    &::after {
      animation-delay: 1.8s;
    }
  }
}

.single-star {
  &::before,
  &::after {
    animation: shining 3s ease-in-out infinite;
  }

  &:nth-child(1) {
    top: calc(50% - 60px);
    left: calc(50% - 250px);
    animation-delay: 1s;

    &::before,
    &::after {
      animation-delay: 1s;
    }
  }

  &:nth-child(2) {
    top: calc(50% - 50px);
    left: calc(50% - 300px);
    animation-delay: 1.2s;

    &::before,
    &::after {
      animation-delay: 1.2s;
    }
  }

  &:nth-child(3) {
    top: calc(50% - 40px);
    left: calc(50% - 150px);
    animation-delay: 1.4s;

    &::before,
    &::after {
      animation-delay: 1.4s;
    }
  }

  &:nth-child(4) {
    top: calc(50% - -50px);
    left: calc(50% - 150px);
    animation-delay: 1.6s;

    &::before,
    &::after {
      animation-delay: 1.6s;
    }
  }

  &:nth-child(5) {
    top: calc(50% - -10px);
    left: calc(50% - 250px);
    animation-delay: 1.8s;

    &::before,
    &::after {
      animation-delay: 1.8s;
    }
  }
}

@keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}

@keyframes falling {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(800px);
  }
}

@keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}

@media only screen and (max-width: 1024px) {
  @keyframes falling {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(200px);
    }
  }
}
